import "./App.css";
import mausi from "./media/mausi.mp3";
import osiris from "./media/osiris.mp3";
import ways from "./media/ways.mp3";
import uniform from "./media/uniform.mp3";
import willisau from "./media/willisau.mp3"
import talk from "./media/talk.mp3"
import tied from "./media/tied.mp3"
import schotfon from "./media/shotfon.mp3"
import { AudioPlayerControlSprite, AudioPlayer, TrackType } from "react-audio-player-pro";
import reactAudioPlayerProStyle from "react-audio-player-pro/dist/style.css";
import logo from "./media/logobeurre.svg"
import image from "./media/beurre.png"

const audioTrackList = [
  {
    src: uniform,

    mediaMetadata: {
      title: "Uniform",
      artist: "Beurre",
    },
  },
  {
    src: talk,

    mediaMetadata: {
      title: "Talk",
      artist: "Beurre",
    },
  },
  {
    src: tied,

    mediaMetadata: {
      title: "Tied",
      artist: "Beurre",
    },
  },
  {
    src: ways,

    mediaMetadata: {
      title: "Ways",
      artist: "Beurre",
    },
  },
  {
    src: osiris,

    mediaMetadata: {
      title: "Osiris",
      artist: "Beurre",
    },
  }, 
  {
    src: schotfon,

    mediaMetadata: {
      title: "2300",
      artist: "Beurre",
    },
  },
  {
    src: willisau,

    mediaMetadata: {
      title: "6130",
      artist: "Beurre",
    },
  },
  {
    src: mausi,

    mediaMetadata: {
      title: "Mausi",
      artist: "Beurre",
    },
  }, 
];

function App() {
  return (
    <div className="App">
      <div className="content">
        <img src={logo} alt="logo" />
        {/* <h3>Avalaible for concerts from January 2023</h3> */}
        <h2>Video for UNIFORM</h2>        
        <div className="video-responsive">
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/2kwDXeZJdXU?si=AAs-jEOsrkaiEIM8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>        <h2>OXT TO ANYONE - out August 11th 2023 on Hummus Records</h2>
        </div>
        <AudioPlayerControlSprite />
        <AudioPlayer className="player" trackList={audioTrackList} />
        <h2>Beurre is :</h2>
        <p>Elischa Heller (Film2, Tanche) - vocals & synth <br />Luc Hess (Coilguns, Trounce) - drums<br /> Chadi Messmer (Closet Disco Queen & The Flying Raclettes, Tanche, Jacob Hannes) - bass </p>
        <img src={image} alt="band picture" />
        <h2>Single TIED</h2>
        <div className="video-responsive">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/JA18fluLKVU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <h2><a target="_blank" href="https://kdrive.infomaniak.com/app/share/118215/8b879453-9fde-4815-b227-8540031eba72">Press kit</a></h2>

        <h2>Contact</h2>
        <p>Chadi Messmer</p>
        <a href="mailto:chadi.messmer@gmail.com">chadi.messmer@gmail.com</a>
        <p>+41 79 357 50 89</p>
        <div class="tour"><h2>OXT TO ANYONE - RELEASE TOUR</h2><p>AUG 11 - <span>ROCK ALTITUDE FESTIVAL</span> - LE LOCLE</p><p>SEPT 07 - <span>ISC CLUB</span> - BERN</p><p>SEPT 08 - <span>HELSINKIKLUB</span> - ZURICH</p><p>SEPT 15 - <span>SEDEL</span> - LUZERN</p><p>OCT 01 - <span>AMALGAME</span> - YVERDON-LES-BAINS</p><p>OCT 06 - <span>PALACE</span> - ST. GALLEN</p><p>OCT 07 - <span>USINE A GAZ</span> - NYON</p><p>OCT 20 - <span>POINT 11</span> - SION</p><p>NOV 04 - <span>CASE A CHOCS</span> - NEUCHATEL</p><p>NOV 24 - <span>LA HOPSCENE</span> - SAIGNELEGIER</p><p>DEC 07 - <span>BAD BONN</span> - DUDINGEN</p><p>DEC 08 - <span>MOKKA</span> - THUN</p></div>
      </div>
    </div>
  );
}

export default App;
